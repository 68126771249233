<template>
  <div class="detail-box">
    <div>
      <el-row
        class="detail-container"
        v-if="productDataList[titleActive - 1].length"
      >
        <el-col
          v-for="(item,key) in productDataList[titleActive - 1]"
          :key="key"
          :xs="item.span.xs"
          :sm="item.span.sm"
        >
          <div class="detail-more">
            <div class="detail-more-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="yichu1">
              <h5 class="yichu1" v-html="item.title"></h5>
              <span v-html="item.detail"></span>
            </div>
            <!-- 浮层 more-->
            <div class="hover-more" @click="goToMore(item.index,item.id)">
              <i>MORE</i>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-else>No data</div>
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
export default {
  components: {
    headerImg,
  },
  props: [],
  data() {
    return {
      //more 跳转的路由
      toRoute: "/product/productDetail",
      //当前选中选项，1为第一个，以此类推
      titleActive: this.$route.query.id || 1,
      productDataList: [
        [
          {
            id: 1,
            index:'de1',
            img: require("../../assets/img/product/20190806145911311.jpg"),
            span: { xs: 24, sm: 12 },
            title: "27 Inches Glasses-free 3D AD Player",
            detail:
              "27 inches glasses-free 3D AD player provides outstanding 3D effects. It is an ideal product for advertisement and supporting both landscape and portrait modes. With embedded content management system, it allows centralized content updating, schedulin",
          },
          {
            id: 1,
            img: require("../../assets/img/product/20190813145836978.jpg"),
            span: { xs: 24, sm: 6 },
            title: "32 inches Glasses-free 3D Smart Display",
            index:'home3',
            detail:
              "An ideal entry level products with fully embedded playback platform and software.Upon that, it perfect fits for window display, in-store product promotion and many other business scenarios.",
          },
          {
            id: 1,
            index:'home2',
            img: require("../../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "42 inches Glasses-free 3D Smart Display",
            detail:
              "42 inches glasses-free 3D LCD landscape display integrates with android platform,provides outstanding 3D effects and zero spinning sensation.It can be used for advertising, digital signage, catering and many other business occasions....",
          },
          {
            id: 1,
            index:'home4',
            img: require("../../assets/img/home/Floor002367-img3.jpg"),
            span: { xs: 24, sm: 6 },
            title: "55 inches Glasses-free 3D Smart Display",
            detail:
              "55 inches glasses-free 3D LCD landscape display integrates with Android platform,provides outstanding 3D effects and zero spinning sensation.It can be used for advertising, digital signage, catering and many other business occasions.",
          },
          {
            id: 1,
            index:"home5",
            img: require("../../assets/img/product/20190813145039587.jpg"),
            span: { xs: 24, sm: 6 },
            title: "55 Inches Glasses-free 3D AD Player",
            detail:
              "Smaller size and no cabinet designed body offer more flexible usage in many different business scenarios. It will fit into a narrow space and bring an outstanding glasses-free 4K visual experience to deliver commercial or informative message....",
          },
          {
            id: 1,
            index:'de2',
            img: require("../../assets/img/product/20190807105629879.jpg"),
            span: { xs: 24, sm: 12 },
            title: "65 Inches Glasses-free 3D AD Player",
            detail:
              "An ideal AD player for anyone who is willing to upgrade existing advertisement facility.With built-in 3D playback plat-form and CMS system, that makes itself appropriate for public space like airport,luxury hotel, stations to deliev-er commerical ....",
          },
        ],
        [
          {
            id: 2,
             index:'vo1',
            img: require("../../assets/img/product/20190813160042791.jpg"),
            span: { xs: 24, sm: 12 },
            title: "3D LCD Video Wall",
            detail: "Model：55inch 3D LCD Video WallSpecification：55inch FHD",
          },
        ],
        [
          {
            id: 3,
             index:'ip1',
            img: require("../../assets/img/product/pro/20191012152132306.png"),
            span: { xs: 24, sm: 12 },
            title: "iPhone 11 pro max",
            detail: "iPhone 11 pro max",
          },
                    {
            id: 3,
             index:'ip2',
            img: require("../../assets/img/product/pro/20191012154145470.png"),
            span: { xs: 24, sm: 6 },
            title: "iPhone 11 pro",
            detail: "iPhone 11 pro",
          },
                    {
            id: 3,
             index:'ip3',
            img: require("../../assets/img/product/pro/20191012154230344.png"),
            span: { xs: 24, sm: 6 },
            title: "iPhone 11",
            detail: "iPhone 11 ",
          },
                    {
            id: 3,
             index:'ip4',
            img: require("../../assets/img/product/pro/20191012154230344.png"),
            span: { xs: 24, sm: 6 },
            title: "iphone xr",
            detail: "iphone xr ",
          },
                              {
            id: 3,
             index:'ip5',
            img: require("../../assets/img/product/pro/20191012155112963.png"),
            span: { xs: 24, sm: 6 },
            title: "iphone xr",
            detail: "iphone xr ",
          },
                              {
            id: 3,
             index:'ip6',
            img: require("../../assets/img/product/pro/20191012155558054.png"),
            span: { xs: 24, sm: 12 },
            title: "iPhone xs max",
            detail: "iPhone xs max",
          },
        ],
        [
                                       {
            id: 4,
             index:'rp1',
            img: require("../../assets/img/product/20190813155319150.jpg"),
            span: { xs: 24, sm: 12 },
            title: "Reality Player 2.0",
            detail: "Reality Player 2.0",
          }, 
        ],
      ],
    };
  },
  methods: {
    goToMore(index,id) {
      //跳转需携带一个id，表明是第几个选项
      this.$router.push(this.toRoute + "?itemId=" + index+'&id='+id);
    },
  },
  watch: {
    $route(to, from) {
      //监听路由，当路由改变时，改变选项
      this.titleActive = to.query.id;
    },
  },
};
</script>
<style lang="less" scoped>
.detail-box {
  padding: 3% 0;
  .detail-container {
    // padding: 0 2%;
    max-width: 1400px;
    margin: 0 auto;
    > div {
      // margin-bottom: 30px;
      height: 427.6px;
      display: block;
      padding: 15px;
      height: 100%;
    }

    .detail-more {
      position: relative;
      background: #f8f8f8;
      padding: 15px;
      height: 100%;
      text-align: left;
      .hover-more {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 104, 183, 0.9);
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        i {
          color: #fff;
          position: absolute;
          width: 100%;
          background: url("~@/assets/img/product/Floor002048-detail.png")
            no-repeat center top;
          padding-top: 40px;
          line-height: 20px;
          top: 50%;
          margin-top: -30px;
          text-align: center;
          font-style: normal;
        }
      }
      .detail-more-img {
        width: 100%;
        height: 235px;
        margin-bottom: 10px;
        background-size: contain;
        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }
    }
    h5 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      line-height: 30px;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
      color: #333;
      display: block;
    }
    span {
      color: #8a8fa0;
      line-height: 24px;
      font-size: 12px;
    }
  }
}
// 待定
// @media (max-width: 767px) {
//   .detail-box {
//     .detail-container {
//       .detail-more {
//         .detail-more-img {

//             height: 105px;

//         }
//       }
//     }
//   }
// }
</style>
